.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: rem(357px);
    height: 100%;
    max-height: 100%;
    min-height: rem(872px);
    display: flex;
    flex-direction: column;
    z-index: 101;
    background-color: #48494f;

    @include --xxl {
        width: rem(282px);
    }

    @include --md {
        position: absolute;
        top: rem(95px);
        left: 0;
        transform: translate(-105%,0);
        transition: .3s;
        width: 50%;
        min-height: auto;
    }

    @include --s {
        width: 100%;
    }

    &.active {
        transform: translate(0,0);
    }
}

.logo {
    width: 100%;
    height: rem(105px);
    min-height: rem(105px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9e9ea;

    @include --xl {
        height: rem(90px);
        min-height: rem(90px);
    }

    @include --lg {
        height: rem(95px);
        min-height: rem(95px);
    }

    @include --md {
        width: rem(200px);
        height: rem(85px);
    }

    @include --s {
        width: rem(180px);
    }

    @include --xs {
        padding-right: rem(10px);
    }
}

.logo--hide-md {
    @include --md {
        display: none;
    }
}

.logo__img-no-ret {
    max-height: 65px;
    width: auto;

    @media all and (-webkit-min-device-pixel-ratio: 1.5){
        display: none;
    }

    @include --md {
        max-height: 55px;
    }
}

.logo__img {
    max-height: 65px;
    width: auto;
    display: none;

    @media all and (-webkit-min-device-pixel-ratio: 1.5){
        display: block;
    }

    @include --md {
        max-height: 55px;
        display: block;
    }
}

.menu {
    width: 100%;
    padding: rem(27px) 0;
    position: relative;

    @include --xl {
        padding: rem(10px) 0;
    }

    @include --md {
        padding: rem(27px) 0;
    }
}

.menu__item {
    width: 100%;
    padding: 0 rem(26px);
    transition: .35s;
    border-left: rem(7px) solid transparent;

    @include --xl {
        padding: 0 rem(23px);
        padding-right: rem(26px);
    }

    @include --s {
        padding: 0;
        border-left: none;

        &.active {
            .icon--arrow {
                transform: translate(0, -50%) rotate(270deg);
            }
        }
    }
}

.menu__link {
    width: 100%;
    display: block;
    padding: rem(13px) rem(65px) rem(13px) rem(55px);
    line-height: rem(18px);
    transition: .35s;
    position: relative;

    &:after {
        content: '';
        width: 30px;
        height: 50%;
        position: absolute;
        background: #1f5984;
        bottom: 0;
        left: calc(100% + 11px);
        transform: skew(-20deg)translate(-50%,0);
        z-index: 102;
        transition: .35s;
        opacity: 0;

        @include --s {
            display: none;
        }
    }

    &:before {
        content: '';
        width: 30px;
        height: 50%;
        position: absolute;
        background: #1f5984;
        top: 0;
        left: calc(100% + 11px);
        transform: skew(20deg)translate(-50%,0);
        z-index: 102;
        transition: .35s;
        opacity: 0;

        @include --s {
            display: none;
        }
    }

    @include --xxl {
        padding: rem(13px) rem(13px);
    }

    @include --xl {
        padding: rem(9px) rem(13px);
    }

    @include --md {
        padding: rem(13px) rem(65px) rem(13px) rem(55px);
    }

    @include --sm {
        padding: rem(13px) rem(30px) rem(13px) rem(30px);
    }

    @include --s {
        margin: 0 10px;
        width: calc(100% - 20px);
    }
}

.menu--top {
    background-color: #246493;
    
    .menu__link {
        color: $w;
        border-top: 1px solid #3777a7;
        border-bottom: 1px solid #1f5984;
        display: inline-block;
        vertical-align: middle;
    }

    .menu__item:hover .sub-menu{
        opacity: 1;
        z-index: 100;
        visibility: visible;

        @include --s {
            position: unset;
            opacity: 1;
            visibility: visible;
        }
    }
    .menu__item:hover .icon--arrow:after{
        opacity: 1;
    }

    .menu__item {
        padding-right: rem(38px);
        &:hover {
            background-color: #1f5984;
            border-left: rem(7px) solid #168ce3;

            .menu__link {
                border-color: #1f5984;
            }

            .menu__link:before {
                transform: skew(20deg)translate(0, 0);
                opacity: 1;
            }

            .menu__link:after {
                transform: skew(-20deg)translate(0, 0);
                opacity: 1;
            }

            @include --s {
                background-color: inherit;
                border-left: none;
            }
        }

        @include --s {
            padding: 0;
            position: relative;
        }

        &:first-child .menu__link {
            border-top: transparent;
        }

        &:last-child .menu__link {
            border-bottom: transparent;
        }

        &.active {
            .sub-menu {
                padding: rem(38px) 0;
            }
        }
    }
}

.menu--bottom {
    background-color: #48494f;

    .menu__link {
        color: #aaacb8;
        border-top: 1px solid #56575c;
        border-bottom: 1px solid #3f4047;
        padding: rem(13px) rem(50px) rem(13px) rem(77px);

        @include --xxl {
            padding: rem(13px) rem(36px);
        }

        @include --xl {
            padding: rem(9px) rem(13px);
        }

        @include --md {
            padding: rem(13px) rem(50px) rem(13px) rem(77px);
        }

        @include --sm {
            padding: rem(13px) rem(30px) rem(13px) rem(46px);
        }

        &:before,
        &:after {
            display: none;
        }
    }

    .menu__item {
        position: relative;

        &:before {
            content: '';
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: #aaacb8;
            position: absolute;
            top: 18px;
            left: 82px;

            @include --xxl {
                left: 40px;
            }

            @include --xl {
                left: 23px;
            }

            @include --md {
                left: 82px;
            }

            @include --sm {
                left: 53px;
            }

            @include --s {
                left: 30px;
            }
        }

        &:hover {
            .menu__link {
                color: $w;
            }

            @include --s {
                background-color: initial;
                border-left: none;

                .menu__link {
                    color: $w;
                    border-color: #3f4047;
                }
            }
        }


        &:first-child .menu__link {
            border-top: transparent;
        }

        &:last-child .menu__link {
            border-bottom: transparent;
        }
    }
}

.icon--arrow {
    position: absolute;
    right: 0;
    opacity: 1;
    margin: 0;
    top: 50%;
    transform: translate(0,-50%);
    transition: .4s;

    &:after {
        content: '\e801';
        font-family: $f;
        color: $w;
        position: relative;
        right: 0;
        opacity: 0;
        transition: .35s;
    }

    @include --s {
        right: 20px;
        transform: translate(0,-50%) rotate(90deg);

        &:after {
            opacity: 1;
        }
    }
}

.sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    z-index: -1;
    opacity: 0;
    transition: .25s;
    background-color: #252527;
    padding: rem(38px) 0;
    width: rem(335px);
    visibility: hidden;

    @include --xl {
        padding: rem(28px) 0;
    }

    @include --md {
        width: 100%;
    }

    @include --md {
        padding: rem(38px) 0;
    }

    @include --s {
        position: unset;
        opacity: 1;
        visibility: visible;
        height: 0;
        padding: 0;
        overflow: hidden;
        transition: height 0.4s;
    }
}

.sub-menu__title {
    text-transform: uppercase;
    color: #919191;
    margin-bottom: rem(5px);
    padding: 0 rem(55px);

    @include --xxl {
        padding: 0 rem(30px);
    }

    @include --md {
        padding: 0 rem(55px);
    }
}

.sub-menu__list {
    margin-bottom: rem(40px);

    &:last-child {
        margin-bottom: 0;
    }

    @include --xxl {
        margin-bottom: rem(30px);
    }

    @include --md {
        margin-bottom: rem(40px);

        &:last-child {
            margin-bottom: rem(40px);
        }
    }
}

.sub-menu__item {
    padding: 0 rem(55px);
    transition: .35s;
    border-left: 0.46667rem solid transparent;

    &:hover {

        .sub-menu__link {
            color: #168ce3;
        }

        .sub-menu__link:before {
            transform: skew(20deg)translate(0, 0);
            background-color: #1f5984;
        }

        .sub-menu__link:after {
            transform: skew(-20deg)translate(0, 0);
            background-color: #1f5984;
        }
    }

    @include --xxl {
        padding: 0 rem(30px);
    }

    @include --md {
        padding: 0 rem(55px);
    }
}

.sub-menu__item:first-child .sub-menu__link {
    border-top: transparent;
}

.sub-menu__item:last-child .sub-menu__link {
    border-bottom: 1px solid #3f3f40;
}

.sub-menu__link {
    color: $w;
    display: block;
    padding: rem(11px) rem(17px);
    border-top: 1px solid #3f3f40;
    border-bottom: 1px solid #1f1e20;
    position: relative;
    transition: .35s;

    @include --xl {
        padding: rem(9px) rem(13px);
    }
}

.bg {
    display: none;
    background: rgba($color: #232323, $alpha: 0.7);
    position: absolute;
    top: rem(95px);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    opacity: 0;
    transition: .35s;

    &.active {
        display: block;
        opacity: 1;
    }
}

.bg-popup {
    display: none;
    background: rgba($color: #232323, $alpha: 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -10;
    opacity: 0;
    transition: .35s;

    &.active {
        display: block;
        opacity: 1;
        z-index: 120;
    }
}

.popup {
    position: fixed;
    top: calc(50% + 0px);
    left: 50%;
    transform: translate(-50%, -52%);
    z-index: 200;

    .form {
        @include --xs {
            padding: rem(30px) rem(20px);
            padding-bottom: rem(50px);
        }
    }

    @include --lg {
        position: absolute;
    }

    @include --s {
        top: 50px;
        transform: translate(-50%, 0);
    }
}

.popup--one {
    width: rem(760px);
    max-width: calc(100% - 40px);
    display: none;

    &.active {
        display: block;
    }

    @include --xs {
        max-width: calc(100% - 20px);
    }
}

.popup--two {
    width: rem(430px);
    max-width: calc(100% - 40px);
    display: none;

    &.active {
        display: block;
    }

    @include --xs {
        max-width: calc(100% - 20px);
    }

    .form__column {
        border-bottom: none;
    }
    .form__row:last-child {
        border-bottom: none;
    }
}

.popup-close {
    position: absolute;
    top: 14px;
    right: 14px;
    height: rem(20px);
    width: rem(20px);
    line-height: rem(20px);
    background-image: url(../images/icons/cancel.png);
    background-repeat: no-repeat;
    background-size: rem(20px);
    background-position: center;
}