.contacts__row {
    display: flex;
    flex-direction: row;
    background-color: #eaeaea;
}

.contacts__row--show-lg {
    display: none;

    @include --lg {
        display: block;
    }
}

.contacts__col {
    width: 50%;

    @include --lg {
        width: 100%;
    }
}

.contacts__col--hide--lg {
    @include --lg {
        display: none;
    }
}

.contacts__content {
    width: 100%;
    height: 100%;
    padding: rem(57px) rem(57px);

    @include --xxl {
        padding: rem(47px) rem(40px);
    }
}

.contacts__list {
    @include --lg {
        display: flex;
        flex-flow: row wrap;
        align-content: stretch;
        padding-top: rem(55px);
    }

    @include --xs {
        flex-direction: column;
    }
}

.contacts__line {
    margin-bottom: rem(55px);

    &:first-child {
        margin-top: rem(55px);
    }

    &:last-child {
        margin-bottom: 0;
    }

    @include --lg {
        width: 50%;
        height: 25%;
        padding-right: rem(50px);
        min-width: 450px;

        &:first-child {
            margin-top: 0;
        }
    
        &:last-child {
            margin-bottom: rem(55px);
        }

        &:nth-child(2) {
            order: 1;

            @include --xs {
                order: initial
            }
        }
    }

    @include --xs {
        min-width: auto;
        width: 100%;
        padding-right: 0;
    }
}

.contacts__subtitle {
    font-family: $gopro;
    font-size: rem(18px);
    line-height: rem(18px);
    color: #afafaf;
    margin-bottom: rem(12px);
}

.contacts__text {
    font-family: $gopro;
    font-size: rem(20px);
    line-height: rem(18px);
    color: $b;
}

.contacts__link {
    font-family: $gopro;
    font-size: rem(20px);
    line-height: rem(18px);
    color: $blue;
    transition: .35s;

    &:hover {
        color: #232323;
    }

    @include --xs {
        display: block;
    }
}

.contacts__icon {
    float: right;
    display: inline-block;
    margin-top: rem(-22px);
    margin-left: rem(20px);
    margin-right: rem(34px);

    @include --xs {
        float: none;
        margin-top: rem(30px);
        margin-left: 0;
    }
}

.contacts__icon--viber {
    position: relative;

    &:after {
        content: '\e806';
        font-family: $f;
        color: #919191;
        transition: .2s;
        font-size: rem(44px);
        line-height: initial;
        display: inline-block;
    }

    &:hover:after {
        color: #7f4da0;
    }

}

.contacts__icon--whatsapp {

    &:after {
        content: '\f232';
        font-family: $f;
        color: #919191;
        transition: .35s;
        font-size: rem(50px);
        line-height: initial;
        display: inline-block;
        margin-top: rem(-4px);
    }

    &:hover:after {
        color: #40c36b;
    }

}