.footer {
    padding-top: rem(127px);
}

.footer__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: rem(10px);
    padding-right: rem(60px);

    @include --lg {
        padding-right: rem(30px);
    }
}

.footer__nav--hide-sm {
    @include --sm {
        display: none;
    }
}

.footer__nav--show-sm {
    display: none;

    @include --sm {
        display: flex;
    }

    @include --xs {
        flex-direction: column;
    }
}

.footer__col {
    width: 50%;

    @include --xs {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: rem(20px);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.footer__item {
    flex: 1 0 auto;
    position: relative;

    &:after {
        content: '';
        height: rem(49px);
        width: rem(1px);
        background: #cdcdd2;
        position: absolute;
        top: 0;
        right: rem(45px);

        @include --xxxl {
            right: rem(22.5px);
        }

        @include --lg {
            display: none;
        }
    }
}

.footer__item--call {
    @include --sm {
        margin-top: rem(30px);
    }

    @include --xs {
        order: -1;
        margin-top: 0;
        margin-bottom: rem(30px);
    }
}

.footer__item--call:after {
    @include --xxxl {
        display: none;
    }

    @include --xxl {
        display: block;
    }

    @include --lg {
        display: none;
    }
}

.footer__item--slogan {
    @include --xxl {
        display: none;
    }
}

.footer__item--last {
    display: flex;
    justify-content: flex-end;
    margin-left: rem(-140px);

    &:after {
        display: none;
    }

    @include --xxxl {
        margin-left: rem(-90px);
    }

    @include --xxl {
        margin-left: 0;
        justify-content: space-between;
    }

    @include --lg {
        justify-content: flex-end;
    }

    @include --sm {
        justify-content: space-between;
    }

    @include --s {
        flex-direction: column;
        align-items: flex-end;
    }

    @include --xs {
        flex-direction: row;
    }
}

.slogan {
    position: relative;
    padding-left: rem(42px);

    &:before {
        content: '\e809';
        font-family: $f;
        position: absolute;
        top: rem(-7px);
        left: 0;
        color: $blue;
        font-size: rem(30px);
    }
}

.slogan__text {
    font-family: $goprom;
    font-size: rem(15px);
}

.socials {
    display: flex;
    flex-direction: row;
    padding: 0 rem(50px);
    margin-top: rem(-10px);
    padding-top: rem(10px);

    @include --xxxl {
        padding: 0 rem(20px);
        padding-top: rem(10px);
    }

    @include --xxxl {
        padding-left: rem(30px);
        padding-right: rem(20px);
    }

    @include --lg {
        display: none;
    }

    @include --sm {
        display: flex;
        padding: 0;
        flex-direction: column;
        justify-content: space-between;
    }

    @include --s {
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: rem(10px);
    }

    @include --xs {
        flex-direction: column;
        margin-bottom: 0;
    }
}

.socials__link {
    margin-right: rem(35px);

    &:last-child {
        margin-right: 0;
    }

    @include --xs {
        margin-right: 0;
        margin-bottom: rem(25px);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.socials__link--viber {

    &:after {
        content: '\e806';
        font-family: $f;
        color: #919191;
        transition: .2s;
        font-size: rem(44px);
        line-height: initial;
        display: inline-block;
    }

    &:hover:after {
        color: #7f4da0;
    }
}

.socials__link--whatsapp {
    &:after {
        content: '\f232';
        font-family: $f;
        color: #919191;
        transition: .35s;
        font-size: rem(50px);
        line-height: initial;
        display: inline-block;
        margin-top: rem(-4px);
    }

    &:hover:after {
        color: #40c36b;
    }
}

.create {
    transform: translate(0,-30%);

    @include --sm {
        transform: translate(0, 0);
    }

    @include --xs {
        order: -1;
    }
}

.create__sub {
    font-size: rem(14px);
    font-family: $gopro;
    color: #afafaf;
    margin-bottom: rem(3px);
}

.create__link {
    padding: rem(2px) 0;
    width: rem(126px);
    border: 2px solid #3b444e;
    color: #3b444e;
    text-transform: uppercase;
    text-align: center;
    display: block;
    transition: .35s;

    &:hover {
        background-color: #3b444e;
        color: $w;
    }
}

.create__bold {
    font-family: $goprob;
}

.footer__line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ababb4;
    margin-top: rem(50px);
    padding-top: rem(28px);
    padding-bottom: rem(28px);
    padding-left: rem(10px);
    padding-right: rem(60px);

    @include --lg {
        padding-right: rem(30px);
        margin-top: rem(30px);
    }

    @include --md {
        flex-flow: row wrap;
    }
}

.footer__copy {
    color: #676767;

    @include --lg {
        text-align: center;
    }

    @include --xs {
        text-align: left;
    }
}

.footer__copy--first {
    @include --xl {
        display: none;
    }
}

.footer__copy--second {
    display: none;

    @include --xl {
        display: block;
    }

    @include --md {
        width: 50%;
    }

    @include --s {
        width: 100%;
    }
}

.footer__policy {
    text-decoration: underline;
    color: #676767;
    transition: .35s;

    &:hover {
        color: #232323;
    }

    @include --lg {
        text-align: center;
    }

    @include --md {
        width: 100%;
        order: 1;
        margin-top: rem(20px);
    }

    @include --xs {
        text-align: left;
    }
}

.footer__mail {
    text-decoration: underline;
    color: $blue;
    transition: .35s;

    &:hover {
        color: #232323;
    }

    @include --lg {
        text-align: center;
    }

    @include --md {
        width: 50%;
    }
}

.footer__mail--hide-s {
    @include --s {
        display: none;
    }
}