@mixin --xxxl { // max-width: 1779px
    @media screen and (max-width: $--xxxl) {
        @content;
    }
}

@mixin --xxl { // max-width: 1679px
    @media screen and (max-width: $--xxl) {
        @content;
    }
}

@mixin --xl { // max-width: 1439px
    @media screen and (max-width: $--xl) {
        @content;
    }
}

@mixin --lg { // max-width: 1279px
    @media screen and (max-width: $--lg) {
        @content;
    }
}

@mixin --md { // max-width: 1023px
    @media screen and (max-width: $--md) {
        @content;
    }
}

@mixin --sm { // max-width: 767px
    @media screen and (max-width: $--sm) {
        @content;
    }
}

@mixin --s { // max-width: 640px
    @media screen and (max-width: $--s) {
        @content;
    }
}

@mixin --xs { // max-width: 479px
    @media screen and (max-width: $--xs) {
        @content;
    }
}

@function rem($px) {
    @return $px / 15px+0rem;
}