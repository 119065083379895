.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: rem(357px);

    @include --xxl {
        padding-left: rem(282px);
    }

    @include --md {
        padding-left: 0;
    }
}

.section {
    position: relative;
    flex: 1 0 auto;
}

.section-wrap {
    padding: rem(57px) rem(58px);
    background-color: #eaeaea;

    @include --xl {
        padding: rem(50px) rem(40px);
    }

    @include --sm {
        padding: rem(40px) rem(15px);
    }
}

.container {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.image-wrap {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.footer {
    flex: 0 0 auto;
}

input, textarea {
    border-radius: 0 !important;
}