html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-family: $gopro;
    line-height: 1.42;
    color: $dark;
    background-color: $w;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*, *::before, *::after {
    box-sizing: border-box;
}
h1, h2, h3 {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

.visuallyhidden {
    position: absolute;
    width: 1px!important;
    height: 1px!important;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}
