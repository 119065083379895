// import pages
@import "common/_mixins.scss";
@import "common/_variables.scss";
@import "common/adjustment.scss";
@import "common/base.scss";
@import "common/fonts.scss";
@import "pages/answers.scss";
@import "pages/contacts.scss";
@import "pages/footer.scss";
@import "pages/header.scss";
@import "pages/index.scss";
@import "pages/preloader.scss";
@import "pages/price.scss";
@import "pages/sidebar.scss";
@import "pages/tools.scss";
@import "pages/typical-service.scss";