.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: rem(105px);

    @include --xl {
        height: rem(90px);
    }

    @include --lg {
        height: rem(95px);
    }

    @include --md {
        flex-direction: column;
        height: initial;
    }
}

.header__row--show-md {
    display: none;
    background-color: #e9e9ea;
    flex-direction: row;
    justify-content: space-between;

    @include --md {
        display: flex;
    }

    @include --xs {
        flex-direction: column;
    }
}

.header__col {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include --xs {
        width: 100%;
        align-items: initial;
        justify-content: space-between;
    }
}

.header__nav {
    display: flex;
    flex-direction: row;
    padding-top: rem(38px);
    padding-left: rem(118px);
    padding-right: rem(64px);
    justify-content: space-between;
    flex: 1;

    @include --xxl {
        padding-left: rem(30px);
        padding-right: rem(30px);
    }

    @include --xxl {
        padding-top: 0;
        align-items: center;
    }

    @include --lg {
        padding-top: rem(28px);
    }

    @include --md {
        padding: rem(27px) rem(15px);
    }

    @include --xs {
        flex-direction: column;
        align-items: flex-start;
        padding: rem(22px) rem(15px);
    }
}

.address {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-left: rem(34px);

    &:before {
        content: '\e804';
        font-family: $f;
        color: #168ce3;
        position: absolute;
        left: rem(2px);
        font-size: rem(33px);
        top: rem(-10px);
    }
}

.address--desktop {
    @include --xl {
        display: none;
    }
}

.address--notebook {
    display: none;

    @include --xl {
        display: flex;
        flex-direction: column;
    }

    @include --s {
        width: 50%;
    }

    @include --xs {
        width: 100%;
    }
}

.address--footer {
    flex-direction: column;
}

.address__caption {
    color: $b;
    font-weight: 600;
    letter-spacing: 0.05em;
}

.address__wrap {
    padding-left: rem(75px);

    @include --xxl {
        padding-left: rem(37px);
    }
}

.address__line--top {
    color: $b;
}

.address__line {
    color: #919191;
    letter-spacing: 0.05em;
}

.calc {
    position: relative;
    padding-left: rem(33px);

    &:before {
        content: '\f1ec';
        font-family: $f;
        color: #168ce3;
        position: absolute;
        left: 0;
        top: rem(-5px);
        font-size: rem(26px);
    }

    @include --lg {
        display: none;
    }
}

.calc__caption {
    color: $b;
    text-decoration: underline;
    transition: .35s;
    letter-spacing: 0.05em;
    font-weight: 600;

    &:hover {
        color: #168ce3;
    }
}

.calc__line {
    color: #919191;
    letter-spacing: 0.05em;
}

.call {
    padding-left: rem(40px);
    position: relative;

    &:before {
        content: '\e805';
        font-family: $f;
        color: #168ce3;
        position: absolute;
        left: rem(3px);
        font-size: rem(31px);
        top: rem(-10px);
    }

    @include --xs {
        width: 100%;
        margin-top: rem(20px);
    }
}

.call__caption {
    display: block;
    color: $b;
    transition: .35s;
    font-weight: 600;
    letter-spacing: 0.05em;

    &:hover {
        color: #168ce3;
    }
}

.call__line {
    display: block;
    color: #168ce3;
    transition: .35s;
    letter-spacing: 0.05em;

    &:hover {
        color: $b;
    }
}

.header__letter {
    width: rem(105px);
    background-color: #168ce3;
    transition: .35s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: '\e803';
        font-family: $f;
        color: $w;
        font-size: rem(50px);
    }

    &:hover {
        background-color: darken(#168ce3, 10%);
    }

    @include --md {
        height: 100%;
    }

    @include --s {
        width: rem(95px);
    }

    @include --xs {
        width: 50%;
        height: auto;
    }
}

.header__letter--hide-md {
    @include --md {
        display: none;
    }
}

.header__calc {
    width: rem(105px);
    height: rem(85px);
    background-color: #168ce3;
    transition: .35s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1px;

    &:before {
        content: '\f1ec';
        font-family: $f;
        color: $w;
        font-size: rem(50px);
    }

    &:hover {
        background-color: darken(#168ce3, 10%);
    }

    @include --md {
        height: 100%;
    }

    @include --s {
        width: rem(95px);
    }

    @include --xs {
        width: 50%;
        height: auto;
        padding: rem(5px) 0;
    }
}

.hamburger {
    height: 100%;
    width: rem(85px);
    background: #fff;
    border: initial;
    cursor: pointer;
    outline: none;

    @include --xs {
        height: auto;
        order: -1;
        background-color: transparent;
    }
}

.hamburger__menu {
    display: inline-block;
    background: $b;
    width: 43px;
    height: 3px;
    position: relative;
    transition: .3s;
    -webkit-transition: background 0 0.3s;
    transition: background 0 0.3s;
    
    &::before, &::after {
      -webkit-transition-duration: 0.3s, 0.3s;
      transition-duration: 0.3s, 0.3s;
      -webkit-transition-delay: 0.3s, 0;
      transition-delay: 0.3s, 0;
    }
    
    &:before {
        content: "";
        background: $b;
        width: 43px;
        height: 3px;
        position: absolute;
        top: 10px;
        left: 0;
    }

    &:after {
        content: "";
        background: $b;
        width: 43px;
        height: 3px;
        position: absolute;
        bottom: 10px;
        left: 0;
    }
}

.hamburger.active {
    background-color: #246493;
    .hamburger__menu {
        background: none;

        &:before {
            background: $w;
        }

        &:after {
            background: $w;
        }
        
        &::before {
            top: 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &::after {
            bottom: 0;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        &::before, &::after {
            -webkit-transition-delay: 0, 0.3s;
            transition-delay: 0, 0.3s;
        }
    }

    @include --xs {
        background-color: transparent;

        .hamburger__menu {
            &:before {
                background: $b;
            }
    
            &:after {
                background: $b;
            }
        }
    }
}