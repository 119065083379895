.service {
    height: rem(664px);
    max-height: 100%;
    width: 100%;
    overflow: hidden;

    @include --lg {
        height: rem(857px);
    }

    @include --s {
        height: calc(290px * 4);
    }

    @include --xs {
        height: rem(493px);
    }
}

.service__video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: url(../images/services-bg.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(35, 35, 35, 0.7);
        z-index: 2;
    }

    video {
        width: 100%;
        height: auto;
    }

    @include --sm {
        background-image: url(../images/services-bg-tablets.jpg);
    }
}

.service__list {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    height: 100%;
    width: 100%;
    margin-bottom: -1px;
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    @include --xs {
        position: relative;
    }
}

.service__item {
    height: 50%;
    width: 20%;
    text-align: center;
    border-right: 1px solid #818184;
    border-bottom: 1px solid #818184;

    @include --xl {
        width: 25%;
    }

    @include --lg {
        width: calc(100% / 3);
        height: calc(100% / 3);
    }

    @include --s {
        width: 50%;
        height: rem(290px);
    }

    @include --xs {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

.service__item--empty {
    width: 60%;

    @include --xl {
        width: 50%;
    }

    @include --lg {
        width: calc(100% / 3 * 2);
    }

    @include --s {
        width: 50%;
    }

    @include --xs {
        display: none;
    }
}

.service__link {
    display: block;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    padding-bottom: rem(50px);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: .35s;
        background: rgba($color: #232323, $alpha: 0.5);
        opacity: 0;
        z-index: 0;
    }

    &:hover:before {
        opacity: 1;
    }

    @include --lg {
        padding-bottom: rem(30px);
    }

    @include --xs {
        justify-content: center;

        &:hover:before {
            opacity: 0;
        }
    }
}

.service__image {
    z-index: 1;
    display: block;

    @media all and (-webkit-min-device-pixel-ratio: 1.5){
        display: none;
    }
}

.service__image-ret {
    z-index: 1;
    display: none;

    @media all and (-webkit-min-device-pixel-ratio: 1.5){
        display: block;
    }
}

.service__desk {
    color: $w;
    z-index: 1;
    font-size: rem(16px);
    margin-top: rem(27px);
}

#slideposition {
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    bottom: 100px;
    z-index: 10;

    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .indicator {
        height: rem(15px);
        width: rem(15px);
        border-radius: 50%;
        background-color: #fff;
        margin-right: rem(22px);

        &:last-child {
            margin-right: 0;
        }
    }

    .indicator.active {
        height: rem(11px);
        width: rem(11px);
        background-color: #4d71d0;
        position: relative;

        &:after {
            height: rem(23px);
            width: rem(23px);
            content: '';
            position: absolute;
            border: 1px solid #ababb4;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}

.main__line-promo-desc {
    display: flex;
    flex-direction: row;
    min-height: rem(400px);

    @include --lg {
        flex-direction: column;
    }
}

.promo {
    width: 50%;
    position: relative;

    @include --lg {
        width: 100%;
        min-height: rem(400px);
    }

    @include --xs {
        min-height: rem(450px);
    }
}

.promo__list {
    height: 100%;
    width: 100%;
    position: relative;

    @include --lg {
        position: initial;
    }
}

.promo__item {
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    transition: .35s;

    &.visible {
        opacity: 1;
    }

    &:nth-child(1) {
        background-image: url(../images/promo-11.jpg);
        
        @include --xs {
            background-image: url(../images/promo-1-mob.jpg);
        }
    }

    &:nth-child(2) {
        background-image: url(../images/promo-2.jpg);
    }

    &:nth-child(3) {
        background-image: url(../images/promo-11.jpg);

        @include --xs {
            background-image: url(../images/promo-1-mob.jpg);
        }
    }
}

.promo__content {
    height: 100%;
    width: 100%;
    padding: rem(43px) rem(61px);

    @include --xxl {
        padding: rem(40px) rem(40px);
    }

    @include --lg {
        padding: rem(43px) rem(61px);
    }

    @include --s {
        padding: rem(40px) rem(40px);
    }

    @include --xs {
        padding: rem(40px) rem(10px);
    }
}

.promo__sticky {
    color: $w;
    background-color: $blue;
    padding: rem(7px) rem(19px);
    display: inline-block;
}

.promo__line-top {
    font-size: rem(20px);
    margin-top: rem(80px);
}

.title {
    font-size: rem(30px);
    line-height: rem(35px);
    font-weight: 700;
    text-transform: uppercase;
    font-family: $goprob;

    @include --xs {
        font-size: rem(24px);
    }
}

.title--white {
    color: $w;
}

.title__white-text {
    color: $w;
}

.title__blue-text {
    color: $blue;
}

.title__black-text {
    color: $b;
}

.title__red-text {
    color: $red;
}

.controls {
    position: absolute;
    bottom: rem(27px);
    left: rem(60px);
    display: flex;
    align-items: center;

    @include --xxl {
        left: rem(40px);
    }

    @include --lg {
        left: rem(60px); 
    }

    @include --s {
        left: rem(40px);
    }

    @include --xs {
        left: rem(10px);
    }
}

.controls--services-xs {
    display: none;

    @include --xs {
        display: block;
        position: initial;
        left: 0;
        bottom: 0;

        .controls__prev {
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 10;
            transform: translate(0,-50%);
            border-color: transparent;

            &:hover {
                background-color: transparent;
                border-color: transparent;
            }

            &:before {
                color: #fff;
                font-size: rem(20px);
            }
        }

        .controls__next {
            position: absolute;
            right: 0;
            top: 50%;
            z-index: 10;
            transform: translate(0,-50%);
            border-color: transparent;

            &:hover {
                background-color: transparent;
                border-color: transparent;
            }

            &:before {
                color: #fff;
                font-size: rem(20px);
            }
        }
    }
}

.controls--gallery {
    position: initial;

    .controls__prev {
        margin-right: rem(17px);
    }

    .controls__next {
        margin-left: rem(17px);
    }
}

.progress,
.progress-reviews {
    width: rem(138px);
    height: 1px;
    background-color: #ababb4;
    margin-right: rem(9px);
}

.progress-complete,
.progress-complete-reviews {
    height: 100%;
    background-color: #252527;
}

.count,
.count-reviews,
.gallery__count {
    font-family: $goprom;
    font-size: rem(25px);
}

.gallery__count-two:before {
    content: '/';
    display: inline-block;
    vertical-align: baseline;
}

.controls__btn {
    background: none;
    padding: 0;
    cursor: pointer;
    border: 1px solid #adadb5;
    transition: .35s;
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(38px);
    width: rem(38px);
    margin-right: rem(10px);
    outline: none;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        background-color: $blue;
        border-color: $blue;

        &:before,
        &:before {
            color: $w;
        }
    }
}

.controls__next:before {
    content: '\e801';
    font-family: $f;
    color: #232323;
    transition: .35s;
}

.controls__prev:before {
    content: '\e802';
    font-family: $f;
    color: #232323;
    transition: .35s;
}

.desc {
    width: 50%; 
    background-color: #dedee0;
    padding: rem(54px) rem(48px);

    @include --xxl {
        padding: rem(54px) rem(40px);
    }

    @include --lg {
        width: 100%;
        padding: rem(54px) rem(48px);
    }

    @include --xs {
        padding: rem(40px) rem(10px);
        padding-bottom: rem(60px);
    }
}

.desc__text {
    margin-top: rem(40px);
    margin-bottom: rem(47px);
}

.more {
    text-transform: uppercase;
    border: 1px solid #232323;
    padding: rem(15px) rem(27px);
    font-family: $gopro;
    color: #232323;
    transition: .35s;
    margin-right: rem(25px);
    background: transparent;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    &:after {
        content: '\e807';
        font-family: $f;
        position: relative;
        display: inline-block;
        color: #232323;
        margin-left: rem(15px);
        display: inline-block;
        vertical-align: top;
        transition: .35s;
    }

    &:hover {
        color: $w;
        background-color: $blue;
        border-color: $blue;

        &:after {
            color: $w;
        }
    }

    @include --sm {
        padding: rem(13px) rem(25px);
        margin-right: rem(20px);

        &:last-child {
            margin-right: 0;
        }
    }

    @include --xs {
        font-size: rem(14px);
        padding: rem(13px) rem(19px);
        margin-right: 0;
    }
}

.more--white {
    border: 1px solid $w;
    color: $w;

    &:after {
        color: $w;
    }

    &:hover {
        color: $w;
        background-color: $blue;
        border-color: $blue;

        &:after {
            color: $w;
        }
    }
}

.more--download {
    width: max-content;
    display: none;
    float: right;

    span {
        text-transform: none;
    }

    &:after {
        content: '';
    }

    &:hover {
        background-color: $red;
        border-color: $red;
    }

    @include --xxl {
        display: inline-block;
        float: none;
        margin-top: rem(20px);
    }

    @include --sm {
        margin-top: rem(15px);
    }
}

.more--download.more--service {
    float: none;
}
.more--download.more--desktop {
    display: block;
    margin-right: 0;

    &.fixed {
        position: fixed;
        top: 0;
        right: rem(58px);
    }

    @include --xxl {
        display: none;
    }
}

.more--service {
    position: initial;
    margin-right: 0;
    margin-bottom: rem(20px);
    display: none;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        background-color: $red;
        border-color: $red;
    }

    &.more--desktop {
        display: block;
        text-align: center;

        @include --xxl {
            display: none;
        }
    }
    @include --xxl {
        display: inline-block;
    }

    @include --s {
        margin-top: rem(20px);
    }

    @include --xs {
        margin-top: rem(15px);
    }
}

.more--gallery {
    display: none;
    width: max-content;
    text-align: center;
    margin-left: auto;
    margin-right: auto!important;

    &:after {
        display: none;
    }

    @include --xs {
        display: block;
    }
}

.numbers {
    width: 100%;
    padding: rem(135px) 0;

    @include --lg {
        padding: rem(135px) rem(60px);
    }

    @include --xs {
        padding: rem(60px) rem(10px);
    }
}

.numbers__list {
    width: 100%;
    display: flex;
    flex-direction: row;

    @include --lg {
        flex-flow: row wrap;
        max-width: rem(792px);
        margin: 0 auto;
        position: relative;

        &:after {
            content: '';
            width: 1px;
            height: 100%;
            left: 50%;
            top: 0;
            position: absolute;
            background: #d1d1d6;

            @include --s {
                display: none;
            }
        }

        &:before {
            content: '';
            width: 100%;
            height: 1px;
            top: 50%;
            left: 0;
            position: absolute;
            background: #d1d1d6;

            @include --s {
                display: none;
            }
        }
    }

    @include --s {
        flex-flow: initial;
        flex-direction: column;
    }
}

.numbers__item {
    border-right: 1px solid #d1d1d6;
    padding-left: rem(58px);
    padding-right: rem(58px);
    padding-bottom: rem(36px);

    &:last-child {
        border-right: none;
    }

    @include --xxl {
        padding-left: rem(30px);
        padding-right: rem(30px);
    }

    @include --xl {
        padding-bottom: rem(30px);
    }

    @include --lg {
        width: 50%;
        height: 50%;
        border-right: none;
        padding: 0;
        min-height: rem(203px);

        &:first-child  {
            padding-bottom: rem(30px);
            padding-right: rem(30px);
        }

        &:last-child  {
            padding-top: rem(30px);
            padding-left: rem(50px);
        }

        &:nth-child(2) {
            padding-bottom: rem(30px);
            padding-left: rem(50px);
        }

        &:nth-child(3) {
            padding-top: rem(30px);
            padding-right: rem(50px);
        }
    }

    @include --s {
        width: 100%; 
        border-bottom: 1px solid #d1d1d6;

        &:last-child {
            border-bottom: none;
        }

        &:first-child  {
            padding-bottom: rem(30px);
            padding-right: rem(30px);
            padding-top: rem(30px);
        }

        &:last-child  {
            padding-top: 0;
            padding-left: 0;
            padding-bottom: rem(30px);
            padding-right: rem(30px);
            padding-top: rem(30px);
        }

        &:nth-child(2) {
            padding-left: 0;
            padding-bottom: rem(30px);
            padding-right: rem(30px);
            padding-top: rem(30px);
        }

        &:nth-child(3) {
            padding-top: 0;
            padding-bottom: rem(30px);
            padding-right: rem(30px);
            padding-top: rem(30px);
        }
    } 
}

.numbers__value {
    font-family: $goprom;
    font-size: rem(100px);

    @include --xxl {
        font-size: rem(85px);
    }

    @include --xs {
        font-size: rem(60px);
    }
}

.numbers__sub {
    position: relative;

    &:after {
        content: 'На';
        height: 38px;
        width: 38px;
        background: $blue;
        position: absolute;
        border-radius: 50%;
        font-size: 18px;
        text-align: center;
        line-height: 38px;
        color: #fff;
        left: 3px;
        top: 20px;

        @include --xxl {
            height: 34px;
            width: 34px;
            line-height: 34px;
            top: 16px;
        }

        @media all and (-webkit-min-device-pixel-ratio: 1.5){
            top: 0;
            left: 2px;
        } 

        @include --xs {
            font-size: 14px;
            height: 25px;
            width: 25px;
            line-height: 25px;
            top: 0px;
            left: 0;
        }
    }
}




.numbers__grove {

    &:after {
        content: '\e809';
        font-family: $f;
        color: $blue;
    }
}

.numbers__subject {
    font-family: $goprom;
    font-size: rem(20px);
    color: $blue;

    @include --xxl {
        font-size: rem(18px);
    }

    @include --xs {
        font-size: rem(20px);
    }
}

.numbers__text {
    color: #676767;
}

.main__line-repair-ques  {
    display: flex;
    flex-direction: row;
    min-height: rem(485px);

    @include --xs {
        min-height: rem(540px); 
    }
}

.repair {
    width: 50%;
    background-image: url(../images/repair-bg.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    @include --lg {
        width: 100%;
    }
}

.repair__video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($color: #232323, $alpha: 0.8);
        z-index: 2;
    }

    video {
        width: 100%;
        height: auto;
    }
}

.repair__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: rem(69px) rem(116px);

    @include --xxl {
        padding: rem(69px) rem(75px);
    }

    @include --xs {
        padding: rem(69px) rem(10px);
    }
}

.repair__sticky {
    text-transform: uppercase;
    color: $w;
    background-color: $red;
    position: absolute;
    left: 0;
    top: rem(105px);
    transform: rotate(-90deg) translate(0,-80%);
    padding: rem(7px) rem(15px);

    @include --xs {
        transform: rotate(0deg) translate(0,0);
        top: 0;
    }
}

.repair__text {
    color: $w;
    margin-top: rem(44px);
    margin-bottom: rem(18px);
}

.repair__subject {
    color: $red;
    font-family: $goprob;
    font-size: rem(18px);
    margin-bottom: rem(70px);
}

.ques {
    width: 50%;
    padding: rem(47px) rem(56px);

    @include --xxl {
        padding: rem(47px) rem(40px);
    }

    @include --lg {
        display: none;
    }
}

.ques__list {
    padding-top: rem(41px);
    padding-right: rem(100px);
    padding-bottom: rem(54px);

    @include --xxl {
        padding-right: 0;
    }
}

.ques__heading {
    color: #676767;
    font-size: rem(13px);
}

.ques__date {
    display: inline-block;

    &:after {
        content: '|';
        display: inline-block;
        margin: 0 rem(8px);
    }
}

.ques__ans {
    display: inline-block;
}

.ques__text {
    font-family: $goprom;
    color: #232323;
    transition: .35s;

    &:hover {
        color: $blue;
    }

    p {
        margin-top: rem(4px);
    }
}

.banners__list {
    display: flex;
    flex-direction: row;
    min-height: rem(498px);

    @include --xxl {
        flex-flow: row wrap;
    }
}

.bunners__item {
    width: 25%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    padding: rem(48px) rem(42px) rem(48px) rem(42px);

    @include --xxxl {
        padding: rem(48px) rem(30px) rem(48px) rem(30px);
    }

    @include --xxl {
        width: 50%;
        min-height: rem(450px);
    }

    @include --s {
        width: 100%;
    }

    @include --xs {
        padding: rem(48px) rem(10px) rem(48px) rem(10px);
    }
}

.pitstop {
    background-image: url(../images/pitstop.jpg);
    text-align: center;
}

.pitstop__desc {
    font-size: rem(18px);
    font-family: $goprom;
}

.pitstop__text {
    font-size: rem(15px);
    font-family: $goprom;
    position: absolute;
    bottom: rem(47px);
    width: 100%;
    left: 0;
    padding: 0 rem(42px);

    @include --xxl {
        left: 50%;
        transform: translate(-50%, 0);
        max-width: rem(430px);
        padding: 0;
    }

    @include --lg {
        max-width: rem(300px);
    }
}

.equipment {
    background-color: #265098;
}

.equipment__desc {
    color: #ff9205;
    font-size: rem(18px);
    font-family: $goprom;
    margin-top: rem(44px);
    margin-bottom: rem(42px);

    @include --xxl {
        max-width: rem(500px);
    }

    @include --xs {
        margin-top: rem(30px);
        margin-bottom: rem(35px);
    }
}

.equipment__text {
    color: $w;

    @include --xxl {
        max-width: rem(500px);
    }
}

.outbound {
    background-image: url(../images/outbound.jpg);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($color: #232323, $alpha: 0.6);
        z-index: 1;
    }
}

.outbound__wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: rem(48px) rem(42px) rem(120px) rem(42px);
    z-index: 10;

    .more {
        position: absolute;
        bottom: 58px;
        left: 50px;

        @include --xxxl {
            left: 30px;
        }
    }

    @include --xxxl {
        padding: rem(48px) rem(30px) rem(48px) rem(30px);
    }
}

.outbound__desc {
    color: $w;
    font-size: rem(15px);
    font-family: $goprom;
    margin-top: rem(44px);

    @include --xxl {
        max-width: rem(390px);
    }
}

.reviews {
    background-color: #f8f8f8;

    .controls {
        left: rem(48px);
        bottom: rem(53px);

        @include --lg {
            left: rem(30px);
            bottom: rem(30px);
        }
        @include --xs {
            left: rem(10px);
            bottom: rem(40px);
        }
    }

    @include --xxl {
        width: 50%;
    }

    @include --lg {
        padding-bottom: rem(75px);
    }

    @include --s {
        width: 100%;
    }

    @include --xs {
        min-height: rem(500px);
    }
}

.reviews__list {
    position: relative;
}

.reviews__item {
    opacity: 0;
    transition: .35s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.visible {
        opacity: 1;
    }
}

.reviews__text {
    font-family: $goprom;
    margin-bottom: rem(35px);

    @include --xxl {
        max-width: rem(500px);
    }

    @include --lg {
        margin-bottom: rem(20px);
    }
}

.reviews__name {
    font-size: rem(18px);
    font-family: $goprom;
    color: $blue;

    @include --xxl {
        max-width: rem(500px);
    }
}

.reviews__dark-text {
    color: #232323;
}

.reviews__desc {
    color: #afafaf;

    @include --xxl {
        max-width: rem(500px);
    }
}

.main__line-request-map {
    display: flex;
    flex-direction: row;
    min-height: rem(597px);

    @include --lg {
        .request {
            display: none;
        }
    }

    @include --s {
        min-height: rem(400px);
    }
}
.request {
    width: 50%;
    
}

.form {
    width: 100%;
    height: 100%;
    padding: rem(47px) rem(56px);
    background-color: #f8f8f8;

    @include --xxl {
        padding: rem(47px) rem(40px);
    }

    @include --xs {
        padding: rem(30px) rem(10px);
        padding-bottom: rem(50px);
    }
}

.form__column {
    width: 100%;
    margin-top: rem(37px);
    //border-bottom: 1px solid #d1d1d6;
    padding-bottom: rem(37px);
    //margin-bottom: rem(61px);
    margin-bottom: rem(14px);

    @include --xs {
        margin-bottom: rem(37px);
    }
}

.form__row {
    width: 100%;
    display: flex;
    margin-bottom: rem(37px);

    &:last-child {
        margin-bottom: 0;
        //border-bottom: 1px solid #d1d1d6;
        //padding-bottom: rem(32px);
    }

    @include --xs {
        flex-direction: column;
        margin-bottom: rem(30px);
    }
}

.form__block {
    margin-right: rem(40px);
    width: 100%;

    &:last-child {
        margin-right: 0;
    }

    @include --xs {
        margin-right: 0;
        margin-bottom: rem(30px);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.form__block--textarea {
    position: relative;

    &:after {
        content: '';
        height: 1px;
        width: 100%;
        background: #ababb4;
        display: block;
        position: absolute;
        top: 86px;
    }

    &:before {
        content: '';
        height: 1px;
        width: 100%;
        background: #ababb4;
        display: block;
        position: absolute;
        top: 45px;
    }
}

.form__input {
    border: none;
    border-bottom: 1px solid #ababb4;
    background-color: transparent;
    width: 100%;
    font-size: rem(15px);
    font-family: $gopro;
    padding: rem(18px) 0;
    padding-right: rem(40px);
    outline: none;
    transition: .35s;

    &:hover,
    &:focus,
    &:active {
        border-color: $b;
    }
}

.form__input--user {
    background-image: url(../images/icons/user.png);
    background-repeat: no-repeat;
    background-size: 22px 28px;
    background-position: 99% center;
}

.form__input--call {
    background-image: url(../images/icons/call.png);
    background-repeat: no-repeat;
    background-size: 26px 26px;
    background-position: 99% center;
}

.form__input--email {
    background-image: url(../images/icons/mail.png);
    background-repeat: no-repeat;
    background-size: 33px 15px;
    background-position: 99% center;
}

.form__input--req {
    background-image: url(../images/icons/pencil.png);
    background-repeat: no-repeat;
    background-size: 26px 26px;
    background-position: 99% center;
}

.form__textarea {
    border: none;
    border-bottom: 1px solid #ababb4;
    background-color: transparent;
    width: 100%;
    resize: none;
    font-size: rem(15px);
    font-family: $gopro;
    outline: none;
    transition: .35s;
    line-height: 42px;

    &:hover,
    &:focus,
    &:active {
        border-color: $b;
    }
}

.map {
    width: 50%;

    @include --lg {
        width: 100%;
    }
}

.map--contacts {
    width: 100%;
    height: rem(533px);
}