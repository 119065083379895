.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: #252527;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.preloader__spinner {
    width: 200px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader__percents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: $gopro;
    font-size: 18px;
}

.preloader__animation {
    animation: spin 4s linear infinite;
    
    &:after {
        content: '\e809';
        font-family: $f;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 200px;
        line-height: 150px;
        color: #dedee0;
    }
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
}