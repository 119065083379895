.type-service__wrap {
    background-color: #eaeaea;
}

.type-service__col {
    display: flex;
    flex-direction: row;
    padding-top: rem(44px);
}

.service-tabs__list {
    display: flex;
    flex-direction: row;
    width: rem(674px);
    height: rem(468px);
    flex-flow: row wrap;

    @include --xl {
        width: rem(384px);
        height: rem(578px);
    }

    @include --sm {
        width: 100%;
        height: auto;
    }
}

.service-tabs__item {
    width: calc(100% / 3);
    height: 50%;
    background: #fff;
    border-right: rem(4px) solid #eaeaea;
    border-bottom: rem(4px) solid #eaeaea;

    @include --xl {
        width: calc(100% / 2);
        height: calc(100% / 3);
    }

    @include --sm {
        width: calc(100% / 3);
        height: rem(220px);
        border-right: rem(14px) solid #eaeaea;
        border-bottom: rem(14px) solid #eaeaea;

        &:nth-child(3),
        &:nth-child(6) {
            border-right: none;
        }
    }

    @include --s {
        width: calc(100% / 2);
        height: rem(180px);
        border-right: rem(4px) solid #eaeaea;
        border-bottom: rem(4px) solid #eaeaea;

        &:nth-child(3),
        &:nth-child(6) {
            border-right: rem(4px) solid #eaeaea;
            border-bottom: rem(4px) solid #eaeaea;
        }

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6) {
            border-right: none;
        }
    }
}

.service-tabs__link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-bottom: rem(40px);
    transition: .35s;

    &:hover,
    &.active {
        background-color: #246493;

        .service-tabs__title,
        .service-tabs__sub {
            color: $w;
        }
    }

    @include --xl {
        padding-bottom: rem(27px);
    }

    @include --s {
        padding-bottom: rem(35px);
    }
}

.service-tabs__link > img {

    @include --xl {
        max-width: rem(294px);
    }

    @include --s {
        max-width: rem(234px);
    }
}

.service-tabs__feautured {
    height: rem(464px);
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include --xl {
        height: rem(574px);
    }

    @include --sm {
        display: none;
    }
}

.service-tabs__title {
    font-family: $goprom;
    font-size: rem(16px);
    color: #252527;
    transition: .35s;
    line-height: rem(16px);
    margin-top: rem(12px);

    @include --xs {
        font-size: rem(15px);
    }
}

.service-tabs__sub {
    font-family: $gopro;
    font-size: rem(13px);
    color: #a5a6a7;
    transition: .35s;
    margin-bottom: rem(-20px);

    @include --xs {
        font-size: rem(12px);
    }
}

.description {
    margin-top: rem(35px);
    overflow: hidden;
    width: 100%;

    &.scroll {
        margin-bottom: rem(128px);
    }

    p {
        margin: 1.5em 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include --sm {
        margin-top: rem(26px);
    }
}

.description--fullwidth {
    max-width: 100%;
}

.description__tab {
    background-color: #fff;
    padding: rem(45px) rem(59px);
    margin-bottom: rem(40px);

    &:last-child {
        margin-bottom: 0;
    }

    @include --md {
        padding: rem(40px) rem(50px);
    }

    @include --sm {
        padding: rem(20px) rem(25px);
    }

    @include --xs {
        padding: rem(20px) rem(20px);
    }
}

.description__title {
    font-family: $goprom;
    font-size: rem(20px);
    color: #252527;
    margin-bottom: rem(27px);
}

.description__text {
    font-family: $gopro;
    font-size: rem(15px);
    line-height: rem(22px);
    color: #252527;
}

.description__item {
    position: relative;
    padding-left: rem(32px);
    margin-bottom: rem(15px);

    &:last-child {
        margin-bottom: 0;
    }

    &:before {
        content: '';
        height: rem(8px);
        width: rem(8px);
        border-radius: 50%;
        background: $blue;
        left: rem(2px);
        top: rem(8px);
        position: absolute;
    }
}

.type-service__table {
    width: 100%;
    padding-top: rem(40px);
    position: relative;
}

.type-service__buttons {
    position: initial;
    float: right;
    text-align: right;
    transition: .4s;

    &.fixed {
        position: fixed;
        top: 0;
        right: rem(58px);
    }

    &.hide {
        opacity: 0;
        z-index: -10;
        visibility: hidden;
    }
}

.type-service__line {
    display: flex;
    flex-direction: row;
    min-height: rem(597px);
    margin-top: rem(60px);

    .repair__subject {
        margin-bottom: rem(100px);

        @include --sm {
            margin-bottom: rem(70px);
        }
    }

    @include --lg {
        flex-direction: column;

        .repair {
            min-height: rem(486px);
            height: 100%;
        }

        .request {
            width: 100%;
        }
    }

    @include --xs {
        .repair {
            min-height: rem(540px);
        }
    }
}

.desc-page {
    background-color: #eaeaea;
    padding: rem(56px) rem(57px);

    @include --xl {
        padding: rem(50px) rem(40px);
    }

    @include --sm {
        padding: rem(40px) rem(15px);
    }
}

.title-description {
    text-transform: uppercase;
    font-family: $goprom;
    font-size: rem(20px);
    line-height: rem(22px);
    color: #252527;
}

.description + .title-description {
    margin-top: rem(55px);
}

.blue-desc {
    font-family: $goprom;
    font-size: rem(18px);
    color: #168ce3;
}

.strong-desc {
    font-family: $goprom;
}

.description__row {

    &:before {
        content: '';
        height: 1px;
        width: calc(100% + 150px);
        background: #eaeaea;
        display: block;
        left: -75px;
        position: relative;
        margin: 20px 0;
    }

    &:after {
        content: '';
        height: 1px;
        width: calc(100% + 150px);
        background: #eaeaea;
        display: block;
        left: -75px;
        position: relative;
        margin: 20px 0;
    }
}

.description__row + .description__row:before {
    display: none;
}

.description__subject {
    color: #afafaf;
    display: block;
}

.description-attention {
    border: 1px solid #eaeaea;
    padding: rem(21px) rem(27px);
    width: max-content;

    @include --xxl {
        width: 100%;
    }
}
    