@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/subset-GothamPro.woff2') format('woff2'),
        url('../fonts/subset-GothamPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro Medium';
    src: url('../fonts/subset-GothamPro-Medium.woff2') format('woff2'),
        url('../fonts/subset-GothamPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro Bold';
    src: url('../fonts/subset-GothamPro-Bold.woff2') format('woff2'),
        url('../fonts/subset-GothamPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot?76594867');
    src: url('../fonts/fontello.eot?76594867#iefix') format('embedded-opentype'),
         url('../fonts/fontello.woff2?76594867') format('woff2'),
         url('../fonts/fontello.woff?76594867') format('woff'),
         url('../fonts/fontello.ttf?76594867') format('truetype'),
         url('../fonts/fontello.svg?76594867#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?32635691#fontello') format('svg');
  }
}
*/
 
[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mail:before { content: '\e800'; } /* '' */
.icon-right-open-big:before { content: '\e801'; } /* '' */
.icon-left-open-big:before { content: '\e802'; } /* '' */
.icon-pencil:before { content: '\e803'; } /* '' */
.icon-location:before { content: '\e804'; } /* '' */
.icon-phone:before { content: '\e805'; } /* '' */
.icon-right-1:before { content: '\e807'; } /* '' */
.icon-left:before { content: '\e808'; } /* '' */
.icon-cog:before { content: '\e809'; } /* '' */
.icon-search:before { content: '\e80b'; } /* '' */
.icon-cancel:before { content: '\e80c'; } /* '' */
.icon-rouble:before { content: '\f158'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-calc:before { content: '\f1ec'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */