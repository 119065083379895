// Responsive
$--xxxl: 1779px; //max-width: 1779px;
$--xxl: 1679px; //max-width: 1679px;
$--xl: 1439px; //max-width: 1439px;
$--lg: 1279px; //max-width: 1279px;
$--md: 1023px; //max-width: 1023px;
$--sm: 767px; //max-width: 767px
$--s: 640px; //max-width: 640px
$--xs: 480px; //max-width: 479px;

// Colors
$dark: #232323;
$red: #f74040;
$blue: #168ce3;
$d-blue: #246493;
$d-blue2: #265098;
$b: #000000;
$w: #ffffff;

// Fonts
$gopro: 'Gotham Pro';
$goprob: 'Gotham Pro Bold';
$goprom: 'Gotham Pro Medium';
$f: 'Fontello';